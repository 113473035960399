export const libretranslateLanguages = [
    'Arabic',
    'Azerbaijani',
    'Chinese',
    'Chinese (Traditional)',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Esperanto',
    'Finnish',
    'French',
    'German',
    'Greek',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Kabyle',
    'Korean',
    'Occitan',
    'Persian',
    'Polish',
    'Portuguese',
    'Russian',
    'Slovak',
    'Spanish',
    'Swedish',
    'Turkish',
    'Ukranian',
    'Vietnamese',
]

const langHash = {};
libretranslateLanguages.forEach((e) => langHash[e] = e);

export const libretranslateLanguagesHash = langHash;