<template lang="pug">
		#application-settings-root
			el-row.text-left
				h4 Application settings
				p In this section you can change various settings related to the applications
				h5.text-danger Not Implemented Yet!
</template>

<style lang="scss">

</style>

<script>
export default {
		name: 'application-settings'
}
</script>