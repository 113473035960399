export const $me = {
    username: '',
    first_name: '',
    last_name: '',
    password: '',
    email: '',
    role: localStorage.getItem('role_id'),
    role_name: '',
    last_login: '',
    two_factor_auth: false
}