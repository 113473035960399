<template lang="pug">
		#settings-root
				#settings-section
						.widget
								el-card.box-card
										.clearfix.d-flex.justify-content-start(slot="header")
												h3
														b Settings And Environment
										el-tabs(v-model="currentTab" type="" @tab-click="changeTabFunc" )
												el-tab-pane(label="Dynamic Params" name="dyn_params")
														dynamic-params.mt-3
												el-tab-pane(label="Application settings" name="app_settings")
														application-settings.mt-3
												el-tab-pane(label="Status Mapping" name="status_mapping")
														status-mapping.mt-3
												el-tab-pane(label="Custom Fix" name="custom_fix")
														custom-fix.mt-3
												el-tab-pane(label="Integration Error" name="integration_error")
														integration-error.mt-3



</template>

<style lang="scss"></style>

<script>
import dynamicParams from './dynamic-params';
import applicationSettings from './application-settings';
import StatusMapping from "./status-mapping.vue";
import customFix from "./custom-fix.vue";
import integrationError from "./integration-error.vue";

export default {
		name: 'settings',
		components: { StatusMapping, dynamicParams, applicationSettings, customFix, integrationError },
		data() {
				return {
						currentTab: 'dyn_params'
				}
		},
		methods: {
				changeTabFunc($event) {
						console.log(`Changed to ${$event.name}`);
				},
		}
}
</script>