<template lang="pug">
	#status-mapping-root
		el-row.text-left
			el-form()
				el-row.text-left
					h4 Status mapping
					p You may map a specific status to another status that will be returned to the affiliate in case "Prefer Param Y" is set to "PARSED".
					p Each status can be mapped once with an exception of statuses that have a linked integration - they can be mapped twice.
					br
				el-row(:gutter="24")
					el-col(:span="24")
						el-form-item(label="Templates")
							el-button(type="info" icon="el-icon-plus" @click="addRow('statuses')") Add

				//#todo-currently-commented-out
					el-row.mt-1.mb-1(:gutter="6")
						h4 Filters
					el-row.mt-1.mb-1(:gutter="6")
						el-col(:xs=24 :sm="12" :md="6")
							el-form-item(label="By Keyword")
								el-input( placeholder="Search By Keyword..." v-model="filters.keyword" v-debounce:300ms="filterOut")
						el-col(:xs=24 :sm="12" :md="6")
							el-form-item(label="By integration")
								el-select.w-100(filterable="",  v-model="filters.integration_id" placeholder="Select integration)" @change="filterOut")
									el-option(label="- Connect To Integration -" value="")
									el-option(v-for="o of options.integration" :label="o.label" :value="o.key")

				el-row.mt-1.mb-1.v-desktop(:gutter="6")
					el-col(:xs="24" :sm="6")
						span Original Status&nbsp;
							small.text-success (Fetched from brands)
					el-col(:xs="24" :sm="6")
						span Returned Status&nbsp;
							small.text-success (Returned to affiliates)
					el-col(:xs="24" :sm="6")
						span Notes&nbsp;
							small.text-success (Related notes)
					el-col(:xs="24" :sm="4")
						span Integration&nbsp;
							small.text-success (Optional)
					el-col.v-desktop(:xs="24" :sm="1")
						span Del
				//pre {{options.integration}}
				.template-options(v-bind:class="{'many-items': statuses.length > 10}")
					el-empty(description="No Records..." :image-size="50" v-if="statuses.length === 0")
					el-row.mt-1.mb-1(v-for="(o, i) of statuses" :gutter="6")
						el-col(:xs="24" :sm="6")
							el-input.gap-mobile.v-desktop(v-model="o.key" placeholder="Key" )
							el-form-item.v-mob()
								template(slot="label")
									span Original Status&nbsp;
										small.text-success (Fetched from brands)
								el-input.gap-mobile(v-model="o.key" placeholder="Key")
						el-col(:xs="24" :sm="6")
							el-input.gap-mobile.v-desktop(v-model="o.val" placeholder="Value" )
							el-form-item.v-mob()
								template(slot="label")
									span Returned Status&nbsp;
										small.text-success (Returned to affiliates)
								el-input.gap-mobile(v-model="o.val" placeholder="Value")
						el-col(:xs="24" :sm="6")
							el-input.v-desktop.v-desktop(v-model="o.note" placeholder="Value" )
							el-form-item.v-mob()
								template(slot="label")
									span Notes&nbsp;
										small.text-success (Related notes)
								el-input.v-mob.gap-mobile(type="textarea" v-model="o.note" placeholder="Value")
						el-col(:xs="24", :sm="4")
							el-select.w-100(filterable="",  v-model="o.integration_id" placeholder="Select integration)" )
								el-option(label="- Connect To Integration -" value="")
								el-option(v-for="o of options.integration" :label="o.label" :value="o.key")
							el-form-item.v-mob()
								template(slot="label")
									span Integration&nbsp;
										small.text-success (optional)
								el-select.w-100(filterable="",  v-model="o.integration_id" placeholder="Select integration)" )
									el-option(label="- Connect To Integration -" value="")
									el-option(v-for="o of options.integration" :label="o.label" :value="o.key")
						el-col.v-desktop(:xs="24" :sm="1")
							el-button.w-100(icon="el-icon-minus" type="danger" @click="removeRow(i)")
						el-row.mt-1.mb-1.v-mob
							el-col(:xs="24" :sm="1")
								el-button.w-100(icon="el-icon-delete" type="danger" @click="removeRow(i)") Remove status
						el-divider.v-mob()
				el-row.mt-3()
					el-col(:span="24" )
						.d-flex.justify-content-center
							el-button(type="success" @click.prevent.native="submit" ) Save
							el-button(type="danger" @click.prevent.native="resetForm" ) Reset

</template>


<style lang="scss">
.template-options {
	width: 100% !important;
	&.many-items {
		height: 60vh;
		overflow-y: auto;
	}
}

@media only screen and (max-width: 768px) {
	.gap-mobile {
		margin-bottom: 0.4rem;
		margin-top: 0.4rem;
	}
}
</style>


<script>
export default {
	name: 'status-mapping',
	data() {
		return {
			counter: 0,
			statuses: [],
			filtered_statuses: [],
			original_statuses: [],
			delete_list: [],
			options: {
				integration: []
			},
			filters: {
				keyword: '',
				integration_id: ''
			}
		}
	},
	created() {
		this.$apix.sendHttpRequest('GET', 'components/options', {filters: '9e832481-2c11-4021-ba13-0dcc5487e3f7'})
				.then(options => {
					this.options.integration = options.integration_extra.map(e => Object.assign({}, {
						label: e.bn + ' - ' + e.t + (e.n ? ` (${e.n})` : ''),
						key: e.v,
					}));
				});
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		filterOut() {
			// @todo - current bug - cannot simply filter out entries - saving the current state, leads to unexpected behaviour.
		},
		fetchData() {
			this.$apix.sendHttpRequest('GET', 'status-mapping/get')
					.then((res) => {
						this.original_statuses = [...res.rows];
						this.statuses = [...res.rows];
						this.filtered_statuses = [...res.rows];
						this.counter = res.count+1;
					})
		},
		addRow(type) {
			this[type].unshift({key: `key_${this.counter}`, val: `value_${this.counter}`, note: `lorem ${this.counter}`});
			this.counter++;
		},
		removeRow(i) {
			let slice = this.statuses.splice(i,1);
			if(slice && slice[0] && slice[0].id) this.delete_list.push(slice[0].id);
		},
		submit() {
			let set = this.statuses.map((e,i) => Object.assign({}, e, {idx: i}))
			this.$apix.sendHttpRequest('POST', 'status-mapping/set', { set, remove: this.delete_list })
					.then(res => {
						this.$notify.success({title: 'API Response', message: 'Statuses updated successfully!'})
						this.fetchData();
					});
		},
		resetForm() {
			this.dynamic_params = JSON.parse(JSON.stringify(this.original_dynamic_params));
		}
	}
}
</script>